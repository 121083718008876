import * as React from "react"
import PropTypes from "prop-types"
import "@dnb/eufemia/style";
import { Link, navigate } from "gatsby"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import { Button, Heading, Logo, Space } from "@dnb/eufemia"
import { hamburger, bell, chat, graph_increase } from "@dnb/eufemia/icons"
import person from "../images/MenuIcon/person.png"
import { Section } from "@dnb/eufemia"

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => (
  <LayoutContainer>
    <Grid>
      <Header>
        <HeaderMenuItems>
          <MenuButton
            text="Hjem"
            icon={hamburger}
            on_click={() => {
              console.log("on_click")
            }}
          />
        </HeaderMenuItems>

        <Logo height="48" />

        <HeaderMenuItems>
          <MenuButton
            text="Varslinger"
            icon={bell}
            on_click={() => {
              console.log("on_click")
            }}
          />
          <MenuButton
            text="Innboks"
            icon={chat}
            on_click={() => {
              console.log("on_click")
            }}
          />
          <MenuButton
            text="Dashboard"
            icon={graph_increase}
            on_click={() => {
              navigate(`/dashboard/`)
            }}
          />
          <MenuPersonButton
            text="Meg"
            icon={person}
            on_click={() => {
              console.log("on_click")
            }}
          />
        </HeaderMenuItems>
      </Header>
    </Grid>
    <main>{children}</main>
  </LayoutContainer>
)

const LayoutContainer = styled.div`
  max-width: 71rem;
  margin: 0 auto;
  padding: 0;

  @media screen and (max-width: 71em) {
    padding: 0 1rem;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, [col-start] 1fr);
  column-gap: 1rem;

  @media screen and (min-width: 40em) {
    grid-template-columns: repeat(12, [col-start] 1fr);
  }
`

const HeaderMenuItems = styled.div`
  display: flex;
  gap: 1rem;

  grid-row: 1;

  @media screen and (max-width: 40em) {
    grid-row: 2;
  }

  grid-column: span 1 / -1;

  &:first-of-type {
    grid-column: 1 / -1;
  }
`

const Header = styled.header`
  min-height: 4.5rem;

  /** IE fallback */
  display: flex;
  justify-content: space-between;
  align-items: center;

  @supports (display: grid) {
    display: grid;
  }

  /** Place our Grid items */
  grid-row: 1;
  grid-column: col-start / -1;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-template-rows: 1;

  .dnb-logo {
    justify-self: center;
    grid-column: col-start / -1;
    grid-row: 1;
  }

  @media screen and (max-width: 40em) {
    /* min-height: 6.5rem; not needed? */
    grid-template-rows: 2;

    .dnb-logo {
      grid-row: 1;

      svg {
        width: 80%;
      }
    }
  }

  /* line above */
  position: relative;

  &::after {
    content: "";

    position: absolute;
    z-index: -1;
    left: -100vw;
    bottom: 0;
    width: 100vw;
    height: 1px;

    //background-color: var(--color-mint-green-25);
    //box-shadow: 100vw 0 0 0 var(--color-mint-green-25);
  }
`

const TitleBox = styled(Space)`
  grid-column: col-start / -1;
  grid-row: 2;

  margin-bottom: var(--spacing-medium);

  @media screen and (min-width: 40em) {
    grid-column: col-start / span 8;
  }

  @media screen and (min-width: 50em) {
    grid-column: col-start / span 6;
  }

  @media screen and (min-width: 71em) {
    grid-column: col-start 2 / span 4;

    margin-bottom: var(--spacing-x-large);
  }

  /* background: blue; */
`

const HeadingTitle = styled(Heading)`
  @media screen and (min-width: 40em) {
    margin-top: var(--spacing-xx-large);

    font-size: var(--font-size-xx-large);
    line-height: var(--line-height-x-large);
  }
`
const HeadingText = styled(Heading)`
  margin: 0;
`

const FigureBox = styled(Space)`
  margin: 0 -1rem;

  grid-column: col-start / -1;
  grid-row: 3;

  @media screen and (min-width: 71em) {
    margin: 0;
  }

  img {
    display: block;
    object-fit: none;

    max-width: 100%;
    max-height: 23.5rem;

    @media screen and (min-width: 40em) {
      max-height: 29rem;
    }
  }
`

const FeaturesBox = styled(Space)`
  display: flex;
  flex-direction: column;

  grid-column: col-start / -1;
  grid-row: 4;

  margin: 0 -1rem;
  padding: 0 1rem;

  background-color: var(--color-sand-yellow);

  @media screen and (min-width: 40em) {
    grid-column: col-start 7 / span 6;
    grid-row: 3;

    padding-top: var(--spacing-large);
  }

  @media screen and (min-width: 71em) {
    margin: 0 -0.5rem;
  }

  .dnb-button {
    align-self: center;
    margin: var(--spacing-large) 0;

    @media screen and (min-width: 40em) {
      margin: var(--spacing-x-large) 0 var(--spacing-xx-large);
    }
  }
`
const FeaturesList = styled(Space)`
  margin-left: 2.5rem;
  padding: 0;

  list-style: none;

  @media screen and (min-width: 40em) {
    align-self: center;
    max-width: 23rem;
  }
`

const FeaturesItem = props => (
  <FeaturesItemStyle element="li" className="dnb-lead" {...props} />
)
const FeaturesItemStyle = styled(Space)`
  margin-top: var(--spacing-x-small);

  @media screen and (min-width: 50em) {
    margin-top: var(--spacing-large);
  }

  /* item gfx */
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: -2.5rem;
    width: 1.5em;
    height: 1.5em;

    background-size: cover;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuNjEzMDkgMTMuNDA3NEMzLjM3NDUgMTMuMDY4OCAyLjkwNjYgMTIuOTg3OCAyLjU2OCAxMy4yMjY0QzIuMjI5NCAxMy40NjQ5IDIuMTQ4MzMgMTMuOTMyOCAyLjM4NjkxIDE0LjI3MTRMMy42MTMwOSAxMy40MDc0Wk02LjY3NSAxOS4wNTQ5TDcuMjk4MjMgMTguNjM3N0M3LjI5NDkxIDE4LjYzMjggNy4yOTE1MyAxOC42Mjc4IDcuMjg4MDkgMTguNjIyOUw2LjY3NSAxOS4wNTQ5Wk05LjIzNTUgMTkuMTMxNEw4LjY0NzEgMTguNjY2NEM4LjY0NDEzIDE4LjY3MDEgOC42NDExOSAxOC42NzM5IDguNjM4MjkgMTguNjc3OEw5LjIzNTUgMTkuMTMxNFpNMjEuNTg4NCA0LjcxMjAxQzIxLjg0NTIgNC4zODcwNSAyMS43OSAzLjkxNTQgMjEuNDY1MSAzLjY1ODU1QzIxLjE0MDEgMy40MDE3IDIwLjY2ODQgMy40NTY5MiAyMC40MTE2IDMuNzgxODhMMjEuNTg4NCA0LjcxMjAxWk0yLjM4NjkxIDE0LjI3MTRMNi4wNjE5MSAxOS40ODY5TDcuMjg4MDkgMTguNjIyOUwzLjYxMzA5IDEzLjQwNzRMMi4zODY5MSAxNC4yNzE0Wk02LjA1MTc3IDE5LjQ3MjJDNi40Njk0MSAyMC4wOTYgNy4xNjI3NSAyMC40Nzk2IDcuOTEzMTYgMjAuNTAyMUw3Ljk1Nzk1IDE5LjAwMjdDNy42OTE5OSAxOC45OTQ4IDcuNDQ2MjYgMTguODU4OCA3LjI5ODIzIDE4LjYzNzdMNi4wNTE3NyAxOS40NzIyWk03LjkxMzE2IDIwLjUwMjFDOC42NjM1NyAyMC41MjQ1IDkuMzc4NTcgMjAuMTgyOSA5LjgzMjcxIDE5LjU4NTFMOC42MzgyOSAxOC42Nzc4QzguNDc3MzMgMTguODg5NiA4LjIyMzkyIDE5LjAxMDcgNy45NTc5NSAxOS4wMDI3TDcuOTEzMTYgMjAuNTAyMVpNOS44MjM5IDE5LjU5NjVMMjEuNTg4NCA0LjcxMjAxTDIwLjQxMTYgMy43ODE4OEw4LjY0NzEgMTguNjY2NEw5LjgyMzkgMTkuNTk2NVoiIGZpbGw9IiMwMDM0M0UiLz4KPC9zdmc+Cg==);
  }
`

const MenuPersonButton = props => (
  <MenuButtonStyled
    variant="tertiary"
    icon_position="top"
    icon_size="18px"
    {...props}
  />
)

const MenuButton = props => (
  <MenuButtonStyled variant="tertiary" icon_position="top" {...props} />
)
const MenuButtonStyled = styled(Button)`
  flex-direction: column;
  height: auto;

  .dnb-button__alignment {
    display: none;
  }

  &.dnb-button--has-icon.dnb-button--has-text.dnb-button--icon-position-left {
    padding: 0;

    .dnb-button__text {
      font-size: var(--font-size-x-small);
      &::after {
        left: 0;
      }
    }
    .dnb-button__icon {
      display: flex;
      width: auto;
    }
    .dnb-button__text,
    .dnb-button__icon {
      margin: 0;
      padding: 0;
    }
  }

  &:active:not([disabled]),
  html:not([data-is-touch]) &:active:not([disabled]) {
    color: var(--color-mint-green);
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
